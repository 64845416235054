export enum ExperimentStatus {
    NEW = 'NEW',
    CREATED = 'CREATED',
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    STOPPED = 'STOPPED',
    COMPLETED_BUT_STOPPED = 'COMPLETED_BUT_STOPPED',
}

export enum Page {
    DASHBOARD = 'DASHBOARD',
    INPUTS = 'INPUTS',
    OPTIMIZER = 'OPTIMIZER',
    LOGS = 'LOGS',
    RESULTS = 'RESULTS',
    QUEUE = 'QUEUE',
    AIRFOILS = 'AIRFOILS',
    AIRFOIL_DETAIL = 'AIRFOIL_DETAIL',
    HUB_ATTACHMENTS = 'HUB_ATTACHMENTS',
    HUB_ATTACHMENT_DETAIL = 'HUB_ATTACHMENT_DETAIL',
    STANDARD_PROPELLERS_DATA = 'STANDARD_PROPELLERS_DATA',
    ADMIN = 'ADMIN',
    PROFILE = 'PROFILE',
    PASSWORD_CHANGE = 'PASSWORD_CHANGE',
    SWEEP = 'SWEEP',
    BACKGROUND_TASKS = 'BACKGROUND_TASKS'
}

export enum OffConditionAnalysisParameterOLD {
    RPM = 1,
    V_INF = 2,
    ALTITUDE = 3,
    THRUST = 4,
    TORQUE = 5,
    POWER = 6,
    OTHER = -1
}

export enum OffConditionAnalysisParameter {
    RPM = 'RPM',
    V_INF = 'V∞ (ft/s)',
    ALTITUDE = 'Altitude (ft)',
    THRUST = 'Thrust (lbf)',
    TORQUE = 'Torque (lbfin)',
    POWER = 'Power (W)'
}

export enum RotationDirection {
    CLOCKWISE = 'Clockwise',
    COUNTERCLOCKWISE = 'Counterclockwise'
}

export enum UserTaskStatus {
    FAILURE = 'FAILURE', 
    PENDING = 'PENDING',
    RECEIVED = 'RECEIVED',
    RETRY = 'RETRY',
    REVOKED = 'REVOKED',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
    USER_CANCELED = 'USER_CANCELED',
}
