import React, {useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import { Stack,Tooltip,Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Sidebar, {MenuButton} from 'common/Sidebar';
import { Page } from 'constants/enums';
import {get, post} from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import axios, { AxiosError, AxiosResponse } from 'axios';
import WithAuthenticate from 'common/hoc/WithAuthenticate';

export const ExperimentLogs = () => {
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');

    const navigate = useNavigate();

    const [logs, setLogs] = useState([]);
    const [refresh, setRefresh] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(25);
    const [experimentStatus, setExperimentStatus] = useState<any>([]);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (refresh) {
            setIsLoading(true);
            const requests = [
                get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback),
                get(GET_API_URLS.EXPERIMENT_OPTIMIZATION_LOGS(experimentId), expireCallback)
            ];
            axios.all(requests).then(axios.spread((experimentResponse: AxiosResponse, logResponse: AxiosResponse) => {
                if (experimentResponse.status === 200) {
                    setExperimentStatus(experimentResponse.data.experiment.status);
                } else {
                    enqueueSnackbar('Error while loading experiments.', {variant: 'error'});
                }

                if (logResponse.status === 200) {
                    setLogs(logResponse.data['logs'] || []);
                } else {
                    enqueueSnackbar('Error while loading experiment logs', {variant: 'error'});
                }
            })).catch((err:AxiosError) => {
                enqueueSnackbar("Error while loading experiment and it's log.", {variant: 'error'});
            }).finally(() => {
                setIsLoading(false);
                setRefresh(false);
            })
        }
    }, [refresh]);

    const columns: GridColDef[] = [
        {field: 'timestamp', headerName: 'Timestamp', width:240, editable: false, type: 'date'},
        {field: 'message', headerName: 'Message', flex:1, editable: false, sortable: false, type: 'string', renderCell: (params) => {
            return (
                <Tooltip title={params.row.message} arrow followCursor>
                    <div>{params.row.message}</div>
                </Tooltip> 
            );
        }}
    ];

    const cancelExperiment = () => {
        setIsLoading(true);
        post(POST_API_URLS.EXPERIMENT_CANCELATION(experimentId), {}, expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                if (res.data.stopped) {
                    enqueueSnackbar('Canceled the experiment successfully', {variant: 'success'});
                } else {
                    enqueueSnackbar('Experiment has already been completed, canceled, or failed', {variant: 'info'});
                }
            } else {
                enqueueSnackbar('Error while canceling the experiment', {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar('Error while canceling the experiment', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const Menubar = () => {
        return (
            <Stack spacing={2} style={{marginTop:'30px', marginBottom: '20px'}}>
                <MenuButton
                    Icon={CloudSyncIcon}
                    iconColor='black'
                    label='Cloud Sync'
                    onClick={() => setRefresh(true)}
                    disabled={false}
                />
                <MenuButton
                    Icon={CancelIcon}
                    iconColor='black'
                    label='Cancel Job'
                    onClick={cancelExperiment}
                    disabled={experimentStatus === "COMPLETED"}
                />
            </Stack>
        );
    }

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{height: '1156px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.LOGS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        Experiment Logs
                    </Typography>
                    <DataGrid
                        autoHeight
                        columns={columns}
                        rows={logs}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize:number) => setPageSize(newPageSize)}
                        sx={{
                            "& .MuiDataGrid-cell": {
                            padding: '2px 10px'
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </>
    );


}

export default WithAuthenticate(ExperimentLogs);
