import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import {useNavigate, useParams} from 'react-router-dom';
import * as _ from 'lodash';
import JSZip from 'jszip';

import { get, getBinary, isLoggedIn } from 'utils/api';
import CircularSpinner from 'common/CircularSpinner';
import NavigationBar from 'common/NavigationBar';
import { Stack, Typography } from '@mui/material';
import { Sidebar } from 'common/Sidebar';
import { Page } from 'constants/enums';
import OutlinedInput from 'common/OutlinedInput';
import { GET_API_URLS } from 'constants/apiUrls';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { NumberCellTemplate } from '@silevis/reactgrid';

export const CFDAnalysisDetail = () => {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const params = useParams();
    const experimentId = parseInt(params['experimentId'] || '0');
    const cfdAnalysisId = parseInt(params['cfdAnalysisId'] || '0');

    const [cfdParameters, setCFDParameters] = useState({
        atmosphere: '',
        altitude: '',
        pressure: '',
        density: '',
        viscosity: '',
        freestreamVelocity: '',
        rpm: '',
        rotationDirection: '',
        testStandSetup: '',
        maximumIteration: '',
    });
    const [cfdResult, setCFDResult] = useState({
        thrust: '',
        torque: '',
    });
    const [graphs, setGraphs] = useState({
        thrust: null,
        torque: null,
        vMagFrontView: null,
        vMagTopView: null,
        pressureFrontView: null,
        pressureTopView: null,
        efficiencyMonitor: null,
        mechanicalPowerMonitor: null,
        residuals: null,
        surfaceAvgYPlusMonitor: null
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
            return;
        }

        setIsLoading(true);
        get(GET_API_URLS.USER_TASK(cfdAnalysisId), expireCallback).then(async (cfdParametersResponse: AxiosResponse) => {
            if (cfdParametersResponse.status === 200) {
                const user_task = cfdParametersResponse.data.user_task;
                setCFDParameters({
                    atmosphere: _.get(user_task, 'additional_info.atmosphere', ''),
                    altitude: _.get(user_task, 'additional_info.altitude', ''),
                    pressure: _.get(user_task, 'additional_info.pressure', ''),
                    density: _.get(user_task, 'additional_info.density', ''),
                    viscosity: _.get(user_task, 'additional_info.viscosity', ''),
                    freestreamVelocity: _.get(user_task, 'additional_info.freestream_velocity', ''),
                    rpm: _.get(user_task, 'additional_info.rpm', ''),
                    rotationDirection: _.get(user_task, 'additional_info.rotation_direction', ''),
                    testStandSetup: _.get(user_task, 'additional_info.test_stand_setup', ''),
                    maximumIteration: _.get(user_task, 'additional_info.max_iteration', ''),
                });
                setCFDResult({
                    thrust: _.get(user_task, 'result.thrust', ''),
                    torque: _.get(user_task, 'result.torque', ''),
                });

                if (user_task.status === 'SUCCESS') {
                    await getBinary(GET_API_URLS.CFD_ANALYSIS_RESULT_FILES(experimentId, cfdAnalysisId), expireCallback).then((cfdResultFilesResponse: AxiosResponse) => {
                        if (cfdResultFilesResponse.status === 200) {
                            return JSZip.loadAsync(cfdResultFilesResponse.data);
                        } else {
                            enqueueSnackbar('Error while loading CFD analysis files', {variant: 'error'});
                        }
                    }).then((zipFiles) => {
                        if (zipFiles) {
                            const loadGraphs = (filename:string, graphType:string) => {
                                zipFiles['files'][filename].async('blob').then((fileData) => {
                                    const fileURL = URL.createObjectURL(fileData);
                                    setGraphs((prevGraphs) => ({...prevGraphs, [graphType]: fileURL}));
                                })
                            }
                
                            loadGraphs('thrust.png', 'thrust');
                            loadGraphs('torque.png', 'torque');
                            loadGraphs('v_mag_front_view.png', 'vMagFrontView');
                            loadGraphs('v_mag_top_view.png', 'vMagTopView');
                            loadGraphs('pressure_front_view.png', 'pressureFrontView');
                            loadGraphs('pressure_top_view.png', 'pressureTopView');
                            if (Number(_.get(user_task, 'additional_info.freestream_velocity', '0')) > 0) {
                                loadGraphs('efficiency_monitor.png', 'efficiencyMonitor');
                            }
                            loadGraphs('mechanical_power_monitor.png', 'mechanicalPowerMonitor');
                            loadGraphs('residuals.png', 'residuals');
                            loadGraphs('surface_avg_y_plus_monitor.png', 'surfaceAvgYPlusMonitor');
                        }
                    }).catch((err: AxiosError) => {
                        enqueueSnackbar('Error while loading CFD analysis files', {variant: 'error'});
                    })
                }

            } else {
                enqueueSnackbar('Error while loading CFD analysis information', {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while loading CFD analysis information', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const Menubar = () => (<></>);

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <NavigationBar showMenu={true}/>
            <Stack direction='row' alignItems='stretch' justifyContent='flex-start' style={{width:'1550px'}}>
                <div style={{width:'50px'}}>
                    <Sidebar Menubar={Menubar} ActivePage={Page.RESULTS}/>
                </div>
                <Stack spacing={3} style={{paddingLeft: '30px', width: '100%'}}>
                    <Typography 
                        style={{textAlign: 'left', fontSize:'24px', color: '#000000DE', paddingTop:'10px'}}
                    >
                        CFD Analysis Detail
                    </Typography>
                    <Stack spacing={2}>
                        <fieldset className='input-grouping' style={{width: '670px'}}>
                            <legend className='input-grouping-label'>Atmospheric & Operation Conditions</legend>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={2}>
                                    <OutlinedInput
                                        label='Standard Atmosphere'
                                        value={cfdParameters.atmosphere}
                                        onValueChange={(newAtmosphere) => {}}
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={true}
                                        tooltip='Standard atmosphere'
                                    />
                                    <OutlinedInput
                                        label='Altitude (ft)'
                                        value={cfdParameters.altitude}
                                        onValueChange={(newAltitude) => {}}
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={true}
                                        tooltip='Altitude (ft)'
                                    />
                                    
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <OutlinedInput
                                        label='Pressure P (Pa)'
                                        value={cfdParameters.pressure}
                                        onValueChange={(newPressure) => {}}
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={true}
                                        tooltip='Pressure P (Pa)'
                                    />
                                    <OutlinedInput
                                        label='Density &rho; (kg/m^3)'
                                        value={cfdParameters.density}
                                        onValueChange={() => {}}
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={true}
                                        tooltip='Density &rho; (kg/m^3)'
                                    />
                                    <OutlinedInput
                                        label='Viscosity &mu; (kg/m*s)'
                                        value={cfdParameters.viscosity}
                                        onValueChange={(newViscosity) => {}}
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={true}
                                        tooltip='Viscosity &mu; (kg/m*s)'
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <OutlinedInput
                                        label='V∞ (ft/s)'
                                        value={cfdParameters.freestreamVelocity}
                                        onValueChange={(newFreestreamVelocity) => {}}
                                        readOnly={true}
                                        style={{width:'180px', height: '60px'}}
                                        tooltip='Freestream velocity (Airflow speed into propeller system)'
                                    />
                                    <OutlinedInput
                                        label='RPM'
                                        value={cfdParameters.rpm}
                                        onValueChange={(newRPM) => {}}
                                        readOnly={true}
                                        style={{width:'180px', height: '60px'}}
                                        tooltip='Propeller RPM'
                                    />
                                </Stack>
                                <OutlinedInput
                                    label='Rotation Direction'
                                    value={cfdParameters.rotationDirection}
                                    onValueChange={(newRotationDirection) => {}}
                                    readOnly={true}
                                    style={{width:'180px', height: '60px'}}
                                    tooltip='Rotation direction'
                                />
                                <OutlinedInput
                                    label='Test Stand Setup'
                                    value={cfdParameters.testStandSetup}
                                    onValueChange={(newTestStandSetup) => {}}
                                    readOnly={true}
                                    style={{width:'180px', height: '60px'}}
                                    tooltip='Test stand setup'
                                />
                                <OutlinedInput
                                    label='Maximum Iteration'
                                    value={cfdParameters.maximumIteration}
                                    onValueChange={(newMaximumIteration) => {}}
                                    style={{ width: '180px', height: '60px' }} 
                                    readOnly={true}
                                    tooltip='Maximum CFD iteration'
                                />
                                <Stack spacing={1}>
                                </Stack>
                            </Stack>
                        </fieldset>
                        <fieldset className='input-grouping' style={{width: '670px'}}>
                            <legend className='input-grouping-label'>CFD Result</legend>
                            <Stack direction='row' spacing={2}>
                                <OutlinedInput
                                        label='Thrust (lbf)'
                                        value={cfdResult.thrust}
                                        onValueChange={(newThrust) => {}}
                                        readOnly={true}
                                        style={{width:'180px', height: '60px'}}
                                        tooltip='Thrust (lbf)'
                                />
                                <OutlinedInput
                                    label='Torque (lbf-ft)'
                                    value={cfdResult.torque}
                                    onValueChange={(newTorque) => {}}
                                    style={{ width: '180px', height: '60px' }} 
                                    readOnly={true}
                                    tooltip='Torque (lbf-ft)'
                                />
                            </Stack>
                        </fieldset>
                        <div id='graphs' style={{marginTop:'50px'}}>
                            <Stack spacing={4}>
                                <Stack spacing={2} direction='row'>
                                    {graphs.thrust && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Thrust Plot</Typography>
                                            <img 
                                                alt='Thrust plot' 
                                                src={graphs.thrust} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                    {graphs.torque && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Torque Plot</Typography>
                                            <img 
                                                alt='Torque plot' 
                                                src={graphs.torque} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                </Stack> 
                                <Stack spacing={2} direction='row'>
                                    {graphs.vMagFrontView && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Velocity Magnitude Front View</Typography>
                                            <img 
                                                alt='Velocity magnitude front view' 
                                                src={graphs.vMagFrontView} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                    {graphs.vMagTopView && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Velocity Magnitude Top View</Typography>
                                            <img 
                                                alt='Velocity magnitude top view' 
                                                src={graphs.vMagTopView} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    {graphs.pressureFrontView && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Pressure Front View</Typography>
                                            <img 
                                                alt='Pressure front view' 
                                                src={graphs.pressureFrontView} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                    {graphs.pressureTopView && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Pressure Top View</Typography>
                                            <img 
                                                alt='Pressure top view' 
                                                src={graphs.pressureTopView} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    {graphs.efficiencyMonitor && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Efficiency Monitor Plot</Typography>
                                            <img 
                                                alt='Efficiency monitor plot' 
                                                src={graphs.efficiencyMonitor} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                    {graphs.mechanicalPowerMonitor && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Mechanical Power Monitor plot</Typography>
                                            <img 
                                                alt='Mechanical power monitor plot' 
                                                src={graphs.mechanicalPowerMonitor} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    {graphs.residuals && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Residuals Plot</Typography>
                                            <img 
                                                alt='Residuals plot' 
                                                src={graphs.residuals} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                    {graphs.surfaceAvgYPlusMonitor && (
                                        <Stack spacing={1}>
                                            <Typography variant='h6'>Surface Average Y+ Monitor Plot</Typography>
                                            <img 
                                                alt='Surface average Y+ monitor plot' 
                                                src={graphs.surfaceAvgYPlusMonitor} 
                                                style={{width: '700px', height: '400px'}}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        </div>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}

export default CFDAnalysisDetail;