import React, {useCallback, useEffect, useState} from 'react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, TextField, Tooltip as MUITooltip, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';

import CircularSpinner from 'common/CircularSpinner';
import { get, post } from 'utils/api';
import { GET_API_URLS, POST_API_URLS } from 'constants/apiUrls';
import { calculateAtmosphere } from 'components/ExperimentInputs/utilityOtherNew';
import OutlinedInput from 'common/OutlinedInput';
import { isNonNegativeNumeric } from 'utils/utils';
import Dropzone from 'common/Dropzone';
import { Close } from '@mui/icons-material';

interface AddCFDAnalysisProps {
    experimentId: number,
    onRegistrationComplete: () => void
}

export const AddCFDAnalysis = (props: AddCFDAnalysisProps) => {
    const {experimentId, onRegistrationComplete} = props;

    const {enqueueSnackbar} = useSnackbar(); 
    const navigate = useNavigate();

    const [cfdParameters, setCFDParameters] = useState({
        atmosphere: 'STANDARD',
        altitude: '',
        pressure: '',
        density: '',
        viscosity: '',
        freestreamVelocity: '',
        rpm: '',
        rotationDirection: 'CLOCKWISE',
        testStandSetup: 'NO_STAND',
        maximumIteration: '1000',
    });
    // const [propellerCADFile, setPropellerCADFile] = useState<File>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const expireCallback = () => {
        navigate('/login');
    }

    useEffect(() => {
        setIsLoading(true);
        get(GET_API_URLS.EXPERIMENT(experimentId), expireCallback).then((res:AxiosResponse) => {
            if (res.status === 200) {
                let experiment = res.data.experiment;
                const _atmosphere = _.get(experiment, 'atmosphere', 'STANDARD');
                const _altitude = `${Number(_.get(experiment, 'altitude', '5000'))}`
                setCFDParameters({
                    ...cfdParameters,
                    atmosphere: _atmosphere,
                    altitude: _altitude,
                    pressure: calculateAtmosphere(_atmosphere, _altitude).pressure,
                    density: `${Number(experiment.air_density)}`,
                    viscosity: `${Number(experiment.air_viscosity)}`,
                    freestreamVelocity: `${Number(experiment.freestream_velocity)}`,
                    rpm: `${Math.round(Number(_.get(experiment, 'result.rpm', '0')))}`,
                });
            } else {
                enqueueSnackbar("Error while loading the experiment's detail", {variant: 'error'});
            }
        }).catch((res:AxiosError) => {
            enqueueSnackbar("Error while loading the experiment's detail", {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    const areCFDParametersValid = () => {
        return (
            isNonNegativeNumeric(cfdParameters.pressure) && 
            isNonNegativeNumeric(cfdParameters.density) &&
            isNonNegativeNumeric(cfdParameters.viscosity) &&
            isNonNegativeNumeric(cfdParameters.freestreamVelocity) && 
            isNonNegativeNumeric(cfdParameters.rpm) &&
            isNonNegativeNumeric(cfdParameters.maximumIteration)
        );
    }

    const startCFDAnalysis = () => {
        if (!areCFDParametersValid()) {
            enqueueSnackbar('Please input valid operational parameters for CFD analysis', {variant: 'error'});
            return;
        }

        // if (!propellerCADFile) {
        //     enqueueSnackbar('Please upload propeller CAD file', {variant: 'error'});
        //     return;
        // }

        const data = {
            'atmosphere': cfdParameters.atmosphere,
            'altitude': cfdParameters.altitude,
            'pressure': cfdParameters.pressure,
            'density': cfdParameters.density,
            'viscosity': cfdParameters.viscosity,
            'rotation_direction': cfdParameters.rotationDirection,
            'freestream_velocity': cfdParameters.freestreamVelocity,
            'rpm': cfdParameters.rpm,
            'test_stand_setup': cfdParameters.testStandSetup,
            maximum_iteration: cfdParameters.maximumIteration,
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        // formData.append('file', propellerCADFile);
        
        setIsLoading(true);
        post(POST_API_URLS.CFD_ANALYSIS_START(experimentId), formData, expireCallback).then((res: AxiosResponse) => {
            if (res.status === 200) {
                enqueueSnackbar('Started the CFD analysis successfully', {variant: 'success'});
                onRegistrationComplete();
            } else {
                enqueueSnackbar('Error while starting CFD analysis', {variant: 'error'});
            }
        }).catch((err: AxiosError) => {
            enqueueSnackbar('Error while starting CFD analysis', {variant: 'error'});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onFileDrop = useCallback((acceptedFile: Array<File>) => {
        if (acceptedFile.length !== 1) {
            enqueueSnackbar('Please upload only one propeller CAD file', {variant: 'error'});
            return;
        }

        // setPropellerCADFile(acceptedFile[0]);
    }, [])

    const smallSX = {m:1, width: '18ch'};

    return (
        <>
            {isLoading && <CircularSpinner/>}
            <div 
                style={{border: '1px solid #707070', 
                boxShadow:'0px 3px 6px #00000029', borderRadius: '20px', backgroundColor: 'white', color: 'black'}}            
            >
                <form>
                    <Stack spacing={2} style={{marginLeft:'50px', marginRight: '50px', marginTop:'50px'}}>
                        <fieldset className='input-grouping'>
                            <legend className='input-grouping-label'>Atmospheric & Operation Conditions</legend>
                            <Stack spacing={2}>
                                <Stack direction='row' spacing={7}>
                                    <MUITooltip title='Standard Atmosphere' arrow placement='top'>
                                        <FormControl sx={smallSX}>
                                            <InputLabel id="atmosphere" sx={{m:'3'}}>
                                                Standard Atmosphere
                                            </InputLabel>
                                            <Select
                                                id='atmosphere'
                                                label='Standard Atmosphere'
                                                value={cfdParameters.atmosphere}
                                                onChange={(e) => {
                                                    const result = calculateAtmosphere(e.target.value, cfdParameters.altitude)
                                                    setCFDParameters({
                                                        ...cfdParameters, 
                                                        atmosphere: e.target.value,
                                                        pressure: result.pressure.toString(), 
                                                        density: result.rho.toString(), 
                                                        viscosity: result.mu.toString()});
                                                }}
                                                style={{ width: '200px', height: '55px' }}
                                            >
                                                <MenuItem value='STANDARD'>Standard Day</MenuItem>
                                                {/* <MenuItem value='COLD'>Cold Day</MenuItem>
                                                <MenuItem value='HOT'>Hot Day</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </MUITooltip>
                                    <OutlinedInput
                                        label='Altitude (ft)'
                                        value={cfdParameters.altitude}
                                        onValueChange={(newAltitude) => {
                                            const result = calculateAtmosphere(cfdParameters.atmosphere, newAltitude)            
                                            setCFDParameters({ 
                                                ...cfdParameters, 
                                                altitude: newAltitude, 
                                                density: result.rho.toString(), 
                                                viscosity: result.mu.toString()});
                                        } }
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={false}
                                        tooltip='Altitude (ft)'
                                    />
                                    
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <OutlinedInput
                                        label='Pressure P (Pa)'
                                        value={cfdParameters.pressure}
                                        onValueChange={(newPressure) => {
                                            setCFDParameters({ ...cfdParameters, pressure: newPressure });
                                        } }
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={false}
                                        tooltip='Pressure P (Pa)'
                                    />
                                    <OutlinedInput
                                        label='Density &rho; (kg/m^3)'
                                        value={cfdParameters.density}
                                        onValueChange={(newDensity) => {
                                            setCFDParameters({ ...cfdParameters, density: newDensity });
                                        } }
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={false}
                                        tooltip='Density &rho; (kg/m^3)'
                                    />
                                    <OutlinedInput
                                        label='Viscosity &mu; (kg/m*s)'
                                        value={cfdParameters.viscosity}
                                        onValueChange={(newViscosity) => {
                                            setCFDParameters({ ...cfdParameters, viscosity: newViscosity });
                                        } }
                                        style={{ width: '180px', height: '60px' }} 
                                        readOnly={false}
                                        tooltip='Viscosity &mu; (kg/m*s)'
                                    />
                                </Stack>
                                <Stack direction='row' spacing={2}>
                                    <OutlinedInput
                                        label='V∞ (ft/s)'
                                        value={cfdParameters.freestreamVelocity}
                                        onValueChange={(newFreestreamVelocity) => {
                                            setCFDParameters({...cfdParameters, freestreamVelocity: newFreestreamVelocity});
                                        }}
                                        readOnly={false}
                                        style={{width:'180px', height: '60px'}}
                                        tooltip='Freestream velocity (Airflow speed into propeller system)'
                                    />
                                    <OutlinedInput
                                        label='RPM'
                                        value={cfdParameters.rpm}
                                        onValueChange={(newRPM) => {
                                            setCFDParameters({...cfdParameters, rpm: newRPM});
                                        }}
                                        readOnly={false}
                                        style={{width:'180px', height: '60px'}}
                                        tooltip='Propeller RPM'
                                    />
                                </Stack>
                                <MUITooltip title='Rotation Direction' arrow placement='top'>
                                    <FormControl sx={smallSX}>
                                        <InputLabel id="rotationDirection" sx={{m:'3'}}>
                                            Rotation Direction
                                        </InputLabel>
                                        <Select
                                            id='rotationDirection'
                                            label='Rotation Direction'
                                            value={cfdParameters.rotationDirection}
                                            onChange={(e) => {
                                                setCFDParameters({
                                                    ...cfdParameters, 
                                                    rotationDirection: e.target.value
                                                })
                                            }}
                                            style={{ width: '211px', height: '55px' }}
                                        >
                                            <MenuItem value='CLOCKWISE'>Clockwise</MenuItem>
                                            <MenuItem value='COUNTERCLOCKWISE'>Counterclockwise</MenuItem>
                                        </Select>
                                    </FormControl>
                                </MUITooltip>
                                <MUITooltip title='Test Stand Setup' arrow placement='top'>
                                    <FormControl sx={smallSX}>
                                        <InputLabel id="testStandSetup" sx={{m:'3'}}>
                                            Test Stand Setup
                                        </InputLabel>
                                        <Select
                                            id='testStandSetup'
                                            label='Test Stand Setup'
                                            value={cfdParameters.testStandSetup}
                                            onChange={(e) => {
                                                setCFDParameters({
                                                    ...cfdParameters, 
                                                    testStandSetup: e.target.value
                                                })
                                            }}
                                            style={{ width: '211px', height: '55px' }}
                                        >
                                            <MenuItem value='NO_STAND'>No Stand</MenuItem>
                                            {/* <MenuItem value='WACO'>Waco</MenuItem> */}
                                            {/* <MenuItem value='COLORADO'>Colorado</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </MUITooltip>
                                <OutlinedInput
                                    label='Maximum Iteration'
                                    value={cfdParameters.maximumIteration}
                                    onValueChange={(newMaximumIteration) => {
                                        setCFDParameters({ ...cfdParameters, maximumIteration: newMaximumIteration });
                                    } }
                                    style={{ width: '180px', height: '60px' }} 
                                    readOnly={false}
                                    tooltip='Maximum CFD iteration'
                                />
                                {/* <Stack spacing={1}>
                                    <Dropzone
                                        onDrop={onFileDrop}
                                        accept={{'application/x-parasolid': ['.x_t']}}
                                        description="Drag 'n' drop the propeller CAD file here, or click to select the file. If the file already exists, then it'll be overwritten by the new file."
                                        maxFiles={1}
                                    />
                                    {propellerCADFile && (
                                        <Stack direction='row' spacing={2}>
                                            <div
                                                style={{alignSelf:'center', fontSize:'17px', marginBottom:'5px'}}
                                            >
                                                {propellerCADFile.name}
                                            </div>
                                            <IconButton onClick={() => setPropellerCADFile(undefined)}>
                                                <Close/>
                                            </IconButton>
                                        </Stack>    
                                    )}
                                </Stack> */}
                            </Stack>
                        </fieldset>
                        <Button 
                            variant='contained' 
                            type='submit'
                            onClick={(e:any) => {
                                e.preventDefault();
                                startCFDAnalysis();
                            }}
                            style={{width:'344px', height:'45px', margin:'44px auto 30px auto'}}
                        >
                            START CFD ANALYSIS
                        </Button>
                    </Stack>
                </form>
            </div>
        </>
    );

}