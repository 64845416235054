const ROOT_URL = process.env.REACT_APP_API_URL

export const GET_API_URLS = {
    USERS: () => `/users`,
    ADMIN_CHECK: () => `/users/admin_check`,
    PAGINATED_USERS_COUNT: () => `/users/paginate/count`,
    EXPERIMENTS: () => `/experiments`,
    EXPERIMENTS_COMPLETED: () => `/experiments/completed`,
    QUEUED_EXPERIMENTS: () => `/experiments/queue`,
    EXPERIMENTS_ALL: () => `/experiments/all`,
    RESULTS: () => `/experiments/results/all`,
    EXPERIMENT: (id: number) => `/experiments/${id}`,
    EXPERIMENT_OPTIMIZATION_LOGS:(id: number) => `/experiments/${id}/logs`,
    EXPERIMENT_STEP_EXPORT: (id: number) => `/experiments/${id}/export/step`,
    EXPERIMENTS_EXPORT: () => `/experiments/export`,
    EXPERIMENT_RESULT_USING_LOCAL_REYNOLDS_NUMBER: (id: number) => `/experiments/${id}/result/local-reynolds-number-based`,
    EXPERIMENT_RESULT_EXPORT: (id: number) => `/experiments/${id}/export/result`,
    RESULT_FILE_EXPORT: (experimentId: number, fileId: number) => `/experiments/${experimentId}/result/files/${fileId}`,
    RESULT_FILES: (experimentId:number) => `/experiments/${experimentId}/result/files`,
    AIRFOILS: () => `/airfoils`,
    AIRFOIL: (airfoilId:number) => `/airfoils/${airfoilId}`, 
    AIRFOIL_FILE: (airfoilId:number) => `/airfoils/${airfoilId}/get`, 
    AIRFOIL_PROPERTIES_BY_REYNOLDS_NUMBER: (airfoilId:number,reynoldsNumber:number) => `/airfoils/${airfoilId}/reynolds-number-properties/reynolds-number/${reynoldsNumber}`,
    REYNOLDS_NUMBER_PROPERTIES_FILE: (airfoilId:number, reynoldsNumber:number) => `/airfoils/${airfoilId}/reynolds-number-properties/${reynoldsNumber}/file`,
    CREATE_REYNOLDS_NUMBER_PROPERTIES: (airfoilId:number, reynoldsNumber:number) => `/airfoils/${airfoilId}/reynolds-number-properties/${reynoldsNumber}/create`,
    ALL_REYNOLDS_NUMBERS_PROPERTIES: (airfoilId:number) => `/airfoils/${airfoilId}/reynolds-number-properties`,
    REYNOLDS_NUMBER_PROPERTIES: (airfoilId:number, reynoldsNumberPropertiesId:number) => `/airfoils/${airfoilId}/reynolds-number-properties/${reynoldsNumberPropertiesId}`,
    REYNOLDS_NUMBER_CHECK: () => ``,
    HUB_ATTACHMENTS: () => `/hub-attachments`,
    HUB_ATTACHMENT_FILE: (hubAttachmentId: number) => `/hub-attachments/${hubAttachmentId}/file`,
    STANDARD_PROPELLERS_DATA: () => `/standard-propellers-data`,
    STANDARD_PROPELLER_DATA: (standardPropellerDataId: number) => `/standard-propellers-data/${standardPropellerDataId}`,
    STANDARD_PROPELLER_DATA_FILES: (standardPropellerDataId: number) => `/standard-propellers-data/${standardPropellerDataId}/files`,
    STANDARD_PROPELLER_DATA_FILE: (standardPropellerDataId: number, fileId: number) => `/standard-propellers-data/${standardPropellerDataId}/files/${fileId}`,
    USER_TASKS: () => `/user-tasks`,
    USER_TASK: (id: number) => `/user-tasks/${id}`,
    CFD_CLUSTERS: () => `/cfd-clusters`,
    CFD_ANALYSIS_TASKS: (id: number) => `/experiments/${id}/cfd-analysis`,
    CFD_ANALYSIS_RESULT_FILES: (experimentId: number, cfd_analysis_task_id: number) => `/experiments/${experimentId}/cfd-analysis/${cfd_analysis_task_id}`,
}

export const POST_API_URLS = {
    LOGIN: () => `/users/login`,
    USER_REGISTRATION: () => `users/register`,
    PAGINATED_USERS: () => `/users/paginate`,
    USER_PERMISSION_CHANGE: (id:number) => `/users/${id}/permission/change`,
    USER_EMAIL_CHANGE: () => `/users/email-change`,
    USER_PASSWORD_CHANGE: () => `/users/password-change`,
    EXPERIMENT_CREATE: () => `/experiments/create`,
    EXPERIMENT_UPDATE: (id: number) => `/experiments/${id}/update`,
    EXPERIMENT_OPTIMIZATION: (id: number) => `/experiments/${id}/optimize`,
    EXPERIMENT_CANCELATION: (id: number) => `/experiments/${id}/stop`,
    EXPERIMENTS_CANCELATION: () => `/experiments/stop`,
    EXPERIMENT_CL_DISTRIBUTION:() => `/experiments/cl-distribution`,
    EXPERIMENTS_COMPARE_RESULTS: () => `results/experiments/compare`,
    EXPERIMENT_OFF_CONDITION_ANALYSIS: (id: number) => `/experiments/${id}/analysis/off-condition`,
    SWEEP_MULTIPLE_EXPERIMENTS: () => `/sweep/multiple/experiments`,
    SWEEP_STANDARD_MULTIPLE_EXPERIMENTS: () => `/sweep/standard/multiple/experiments`,
    EXPERIMENT_BLADE_STL_EXPORT: (id: number) => `/experiments/${id}/export/stl`,
    EXPERIMENT_CFD_PROPELLER_NX_EXPORT: (id: number) => `/experiments/${id}/export/nx/cfd-propeller`,
    EXPERIMENT_PROPELLER_NX_EXPORT: (id: number) => `/experiments/${id}/export/nx`,
    EXPERIMENT_PROPELLER_STL_EXPORT: (id: number) => `/experiments/${id}/export/stl/propeller`,
    RESULT_FILE_UPLOAD: (id: number) => `/experiments/${id}/result/files/upload`,
    AIRFOIL_INTERPOLATE_POINTS: () => `/airfoils/interpolate-points`,
    AIRFOIL_ADD: () => `/airfoils/add`,
    REYNOLDS_NUMBER_PROPERTIES_ADD: (airfoilId: number) => `/airfoils/${airfoilId}/reynolds-number-properties/add`,
    AIRFOILS_PROPERTIES_DISTRIBUTION: () => `/experiments/airfoils-properties-distribution`,
    HUB_ATTACHMENT_ADD: () => `/hub-attachments/add`,
    STANDARD_PROPELLER_DATA_ADD: () => `/standard-propellers-data/add`,
    CFD_CLUSTER_CREATE: () => `/cfd-clusters/create`,
    CFD_ANALYSIS_START: (id: number) => `/experiments/${id}/cfd-analysis/start`, 
}

export const PUT_API_URLS = {
    AIRFOIL_UPDATE: (airfoilId: number) => `/airfoils/${airfoilId}/update`,
    REYNOLDS_NUMBER_PROPERTIES_UPDATE: (airfoilId: number, reynoldsNumberPropertiesId:number) => `/airfoils/${airfoilId}/reynolds-number-properties/${reynoldsNumberPropertiesId}/update`,
    HUB_ATTACHMENT_UPDATE: (hubAttachmentId:number) => `/hub-attachments/${hubAttachmentId}/update`,
    STANDARD_PROPELLER_DATA_UPDATE: (standardPropellerDataId: number) => `/standard-propellers-data/${standardPropellerDataId}/update`,
    USER_TASK_CANCEL: (id: number) => `/user-tasks/${id}/cancel`,
}

export const DELETE_API_URLS = {
    USER_DELETE: (id: number) => `/users/${id}/delete`,
    EXPERIMENT_DELETE: (id: number) => `/experiments/${id}/delete`,
    RESULT_FILE: (experimentId: number, fileId: number) => `/experiments/${experimentId}/result/files/${fileId}`,
    AIRFOIL_DELETE: (airfoilId: number) => `/airfoils/${airfoilId}/delete`,
    REYNOLDS_NUMBER_PROPERTIES_DELETE: (airfoilId: number, reynoldsNumberPropertiesId:number) => `/airfoils/${airfoilId}/reynolds-number-properties/${reynoldsNumberPropertiesId}/delete`,
    HUB_ATTACHMENT_DELETE: (hubAttachmentId: number) => `/hub-attachments/${hubAttachmentId}/delete`,
    STANDARD_PROPELLER_DATA_DELETE: (standardPropellerDataId: number) => `/standard-propellers-data/${standardPropellerDataId}/delete`,
    STANDARD_PROPELLER_DATA_FILE_DELETE: (standardPropellerDataId: number, fileId: number) => `/standard-propellers-data/${standardPropellerDataId}/files/${fileId}`,
    USER_TASK_DELETE: (id: number) => `/user-tasks/${id}/delete`,
    CFD_CLUSTERE_DELETE: (id: number) => `/cfd-clusters/${id}/delete`,
}